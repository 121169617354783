.anmeldung {
  &__container {
    background-image: linear-gradient(-45deg, #fff, #eee, #ddd, #ccc) !important;
    background-size: 400% 400% !important;
    animation: gradient 10s ease infinite !important;
    padding: 40px !important;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  &__gridSeite {
    max-width: 600px !important;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    margin: 0 auto !important;

    @include coAnmeldungBreakpointOne {
      margin: -12.5px !important;
    }
  }

  &__gridSeiteTitel {
    padding: 25px !important;
    @include coAnmeldungBreakpointTwo {
      padding: 15px !important;
    }
  }

  &__typographyEinzigSeiteTitel {
    font-weight: 600 !important;
    font-size: 140% !important;
    font-family: $special_font !important;
    color: $red_color !important;
    @include coAnmeldungBreakpointTwo {
      font-size: 110% !important;
    }
  }
}
