.border {
  border: 1px solid;
  &__red {
    border: 1px solid $red_color !important;
  }

  &__right--white {
    border-right: 2px solid $white_color !important;
  }
}
