.coWarenscanner {
  &__typographyTitel {
    font-size: 120% !important;
    font-family: $special_font !important;
    font-weight: 600 !important;
    color: $blue_color !important;
  }
  &__typographyTitelRot {
    font-size: 120% !important;
    font-family: $special_font !important;
    font-weight: 600 !important;
    color: $red_color !important;
  }

  &__resultTable {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }

  &__versandpaket--smallwidth {
    display: none !important;
    @include coVersandpaketBreakpoint {
      display: block !important;
    }
  }

  &__versandpaket--fullwidth {
    @include coVersandpaketBreakpoint {
      display: none !important;
    }
  }
}
