.padding {
  &__right12 {
    padding-right: 12px !important;
  }
  &__left12 {
    padding-left: 12px !important;
  }

  &__right20 {
    padding-right: 20px !important;
  }

  &__top30 {
    padding-top: 30px !important;
  }

  &__10 {
    padding: 10px !important;
  }
}
