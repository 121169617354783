.form {
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px $grey_color inset; /* Change the color to your own background color */
  -webkit-text-fill-color: $black_color;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px $grey_color inset; /*your box-shadow*/
  -webkit-text-fill-color: $black_color;
}
