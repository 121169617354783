@mixin coAnmeldungBreakpointOne {
  @media only screen and (max-width: 655px) {
    @content;
  }
}

@mixin coAnmeldungBreakpointTwo() {
  @media only screen and (max-width: 599px) {
    @content;
  }
}

@mixin coNavigationBreakpoint() {
  @media only screen and (max-width: 1100px) {
    @content;
  }
}

@mixin coNichtGefundenBreakpoint() {
  @media only screen and (max-width: 599px) {
    @content;
  }
}

@mixin coVersandpaketBreakpoint() {
  @media only screen and (max-width: 1279px) {
    @content;
  }
}
