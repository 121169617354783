.margin {
  &__topAuto {
    margin-top: auto !important;
  }

  &__top10 {
    margin-top: 10px !important;
  }

  &__top20 {
    margin-top: 20px !important;
  }

  &__top30 {
    margin-top: 30px !important;
  }
}
