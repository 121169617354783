.coNichtGefunden {
  &__wrapper {
    position: absolute !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__text {
    font-size: 150% !important;
    font-weight: 700 !important;
    font-family: $special_font !important;
    @include coAnmeldungBreakpointTwo {
      font-size: 120% !important;
    }
  }
}
