.noWrap {
  white-space: nowrap !important;
}

.backdrop {
  z-index: 20000 !important;
  color: $white_color !important;
}

.Toastify__toast-body {
  width: 100%;
  word-break: break-all;
}

.boxShadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.checkbox__blue {
  color: $blue_color !important;
}

.checkbox__blue:hover {
  background-color: rgba(14, 145, 140, 0.04) !important;
}
