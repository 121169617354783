// import -> resources - essential
@import './scss/fonts.scss';
@import './scss/breakpoints.scss';
@import './scss/colors.scss';
@import './scss/designMixins.scss';

// import -> resources - elements
@import './scss/borer.scss';
@import './scss/button.scss';
@import './scss/container.scss';
@import './scss/form.scss';
@import './scss/grid.scss';
@import './scss/icon.scss';
@import './scss/margin.scss';
@import './scss/miscellaneous.scss';
@import './scss/padding.scss';
@import './scss/table.scss';
@import './scss/typography.scss';
@import './scss/width.scss';

// import -> components
@import './components/Anmeldung/CoAnmeldung.scss';
@import './components/DataMatrixScanner/CoDataMatrixScanner.scss';
@import './components/Navigation/CoNavigation.scss';
@import './components/404/CoNichtGefunden.scss';
@import './components/QRScanner/CQRScanner.scss';
@import './components/Warenscanner/CoWarenscanner.scss';
@import './components/Warenscanner/CoPakettemplate.scss';

#root,
body {
  width: 100%;
}

html,
body,
#root {
  height: 100%;
}

* {
  margin: 0;
}
