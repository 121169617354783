.width {
  &__fitContent {
    width: fit-content !important;
  }

  &__max200 {
    max-width: 200px !important;
  }

  &__min300 {
    min-width: 300px !important;
  }

  &__min265 {
    min-width: 265px !important;
  }

  &__630 {
    width: 630px !important;
  }

  &__max540 {
    max-width: 540px !important;
  }

  &__345 {
    width: 345px !important;
  }

  &__134 {
    width: 151px !important;
  }

  &__maxContent {
    width: max-content !important ;
  }
}
