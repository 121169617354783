$red_color: #bb2205;
$grey_color: #d2d3c9;
$blue_color: #0e918c;
$white_color: #fff;
$black_color: #000;
$dark_blue_color: rgb(14, 17, 51);
$original_red_color: red;
$orange_color: orange;
$green_color: rgb(84, 219, 30);
$dark_blue_color2: rgb(26, 31, 80);
