.pakettemplate {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 10px;

  &__headerButtons {
    margin-top: 10px;
  }

  &__headerButtons button {
    border-radius: 4px !important;
  }

  &__body {
    max-height: 510px;
    overflow-y: auto;
  }

  &__body--max300 {
    max-height: 300px;
    overflow-y: auto;
  }
}

.versandpaket__tablerow_nopadding {
  td {
    padding: 2px !important;
  }
}
