.typography {
  font-size: 110% !important;

  &.flex {
    display: flex;
    align-items: center;
  }

  &__alert {
    align-items: center;
    font-size: 105% !important;
  }

  &__600 {
    font-weight: 600 !important;
  }

  &__abteilungtitel {
    font-size: 130% !important;
    font-weight: 600 !important;
  }

  &__black {
    color: $black_color;
  }

  &__blue {
    color: $blue_color;
  }

  &__red {
    color: $red_color;
  }

  &__breakWord {
    word-break: break-all !important;
  }

  &__dialogTitle {
    font-size: 110% !important;
    font-family: $special_font !important;
    font-weight: 700 !important;
    color: $red_color !important;
  }

  &__dialogSubtitle {
    font-family: $special_font !important;
    font-weight: 700 !important;
    color: $dark_blue_color !important;
  }

  &__einzigSeiteTitel {
    font-weight: 600 !important;
    font-size: 140% !important;
    font-family: $special_font !important;
    border-bottom: 1px solid $red_color;
    width: fit-content;

    @include coAnmeldungBreakpointTwo {
      font-size: 110% !important;
    }
  }

  &__red {
    color: $red_color;
    font-weight: 600 !important;
  }

  &__webseiteTitel {
    user-select: none;
    cursor: pointer;
    font-size: 150% !important;
    font-weight: 700 !important;
    color: #fff !important;
    font-family: $special_font !important;
    @include coAnmeldungBreakpointTwo {
      font-size: 120% !important;
    }
  }

  &__white {
    color: $white_color !important;
  }
}
