.button {
  &__big--drawBorder {
    padding: 15px 40px !important;
    font-size: 100% !important;
    font-weight: 600 !important;
    color: $black_color !important;
    border-radius: 0 !important;
    @include button_border_drawing($black_color, $red_color, 4px, bottom, right);
  }

  &__link--blue {
    font-size: 18px !important;
    color: $blue_color !important;
  }

  &__link--blue.size_small {
    font-size: 14px !important;
  }

  &__middle--blue {
    background-color: $blue_color !important;
    color: $white_color !important;
    font-size: 15px !important;
  }

  &__middle--black {
    background-color: $dark_blue_color2 !important;
    color: $white_color !important;
    font-size: 15px !important;
  }

  &__middle--drawBorder {
    padding: 10px 20px !important;
    font-size: 100% !important;
    font-weight: 600 !important;
    color: $black_color !important;
    border-radius: 0 !important;
    @include button_border_drawing($black_color, $red_color, 4px, bottom, right);
  }

  &__middle--green {
    background-color: $green_color !important;
    color: $white_color !important;
    font-size: 15px !important;
  }

  &__middle--orange {
    background-color: $orange_color !important;
    color: $white_color !important;
    font-size: 15px !important;
  }

  &__middle--originalRed {
    background-color: $original_red_color !important;
    color: $white_color !important;
    font-size: 15px !important;
  }

  &__middle--red {
    background-color: $red_color !important;
    color: $white_color !important;
    font-size: 15px !important;
  }

  &__small--blue {
    background-color: $blue_color !important;
    color: $white_color !important;
    font-size: 12px !important;
  }

  &__small--black {
    background-color: $black_color !important;
    color: $white_color !important;
    font-size: 12px !important;
  }

  &__small--red {
    background-color: $red_color !important;
    color: $white_color !important;
    font-size: 12px !important;
  }

  &__sortingArrowIcon {
    margin-right: 5px !important;
    padding: 2px !important;
    transition: all 0.3s !important;
  }

  &__sortingArrowIcon.active {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &__middle--disabled {
    background-color: grey !important;
    color: $white_color !important;
    font-size: 15px !important;
  }

  &--inGroup.button__middle--blue {
    border: none !important;
    border-radius: 4px !important;
  }
}
