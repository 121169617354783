.container {
  padding: 0;
  margin: 0 !important;
  max-width: unset !important;

  &__vollBreite {
    padding: 0 !important;
    height: 100% !important;
  }
}
