.icon {
  font-size: 400% !important;
  &__check {
    color: $blue_color !important;
    font-size: 200% !important;
    margin-right: 5px;
  }

  &__error {
    color: $red_color !important;
    font-size: 200% !important;
    margin-right: 5px;
  }

  &__darkblue {
    color: $dark_blue_color;
  }

  &__small--darkblue {
    color: $dark_blue_color;
    height: 20px !important;
    width: 20px !important;
  }

  &__small--red {
    color: $red_color;
    height: 20px !important;
    width: 20px !important;
  }

  &__red {
    color: $red_color;
  }

  &__green {
    color: $green_color;
  }

  &__highPriority {
    color: red;
    border-radius: 50%;
    border: 1px solid;
    margin-right: 10px;
    font-size: unset !important;
  }
}
