.coQrScanner {
  &__qrReader {
    width: 100%;

    & > section {
      padding: 0 !important;
      display: flex;
      justify-content: center !important;
    }

    & > section > div {
      display: none !important;
    }

    & > section > video {
      max-width: 800px !important;
      position: static !important;
    }
  }

  &__browserFormControl .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
    display: flex;
    align-items: center;
  }
}

#detach-button-host {
  display: none;
}
