.table {
  &__cell--hover {
    &:hover {
      background-color: $grey_color !important;
      cursor: pointer;
    }
  }

  &__searchBar .MuiInput-formControl::before {
    border-bottom: 2px solid #ddd !important;
  }

  &__searchBar .MuiInput-formControl::after {
    border-color: rgb(184, 177, 177) !important;
  }
}
