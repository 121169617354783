.coNavigation {
  &__topNav {
    overflow: hidden;
    padding: 10px 5px !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    background-color: $dark_blue_color !important;
    max-height: 56px !important;

    @include coNavigationBreakpoint {
      position: relative;
      transition: all 0.5s;

      &.responsive {
        max-height: 524px !important;
      }
    }

    &Link {
      float: left;
      display: block;
      color: $black_color !important;
      text-align: center !important;
      padding: 14px 16px !important;
      text-decoration: none !important;
      background-color: $original_red_color;
      color: $white_color !important;
      border-radius: 20px !important;
      margin-left: 2px !important;
      transition: all 0.2s;

      @include coNavigationBreakpoint {
        height: unset !important;

        margin-left: 0 !important;
      }

      &--active {
        transform: translateY(-5px);
        @include coNavigationBreakpoint {
          transform: none !important;
          background-color: $red_color !important;
          color: $white_color !important;
        }
      }

      &:not(:first-child):not(:last-child):hover {
        transform: translateY(-5px);
        @include coNavigationBreakpoint {
          transform: none !important;
          background-color: $red_color;
          color: $white_color !important;
        }
      }

      &:first-child {
        padding-left: 12px !important;
        user-select: none;
        background-color: $red_color;
        display: none;
        @include coNavigationBreakpoint {
          display: block;
          text-align: left !important;
          float: none;
          text-align: left;
        }
      }

      &:not(:first-child):not(:last-child) {
        @include coNavigationBreakpoint {
          background-color: $original_red_color;
          color: $white_color !important;
          margin-top: 10px !important;
          text-align: left !important;
          float: none;
          text-align: left;
        }
      }
    }

    &Icon {
      display: none;
      cursor: pointer;

      @include coNavigationBreakpoint {
        float: right;
        display: block !important;
        position: absolute;
        right: 5px;
        top: 10px;
      }
    }

    &Typography {
      font-size: 120% !important;
    }
  }
}
